/**
 * This file is a place where you can add monkey patches or prototype overrides
 * This file should be imported in polyfills.ts and not anywhere else!
 */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface BigInt {
    /** 
     * Convert to BigInt to string form in JSON.stringify 
     * If we do not add this we can receive errors such as 
     * 'TypeError: do not know how to serialize a bigint string' from GoCore
    */
    toJSON: () => string;
}
BigInt.prototype.toJSON = function () {
    return this.toString();
};